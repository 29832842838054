import React, { useState, useEffect } from 'react'
import axios from '../../../../axios-exam-api'
import Modal from '../../../../components/Modal/Modal'
import {
    Wrapper,
    ProtocolWrapper,
    ModalImage,
    ModalSingleImage, 
    ColumnWrapper,
    RIQContainer,
    SmallText,
    BoldText
} from "./styles"

const HeatMap: React.FC<{ imageName: string; imageURL: string; modalClicked: boolean; onClose: () => void }> = (props) => {
    const [heatMapFlag, setHeatMapFlag] = useState<boolean>(false)
    const [heatMapError, setHeatMapError] = useState<{ data: { Message: string } }>();
    const [protocolError, setProtocolError] = useState<{ data: { Message: string } }>();
    const [modalImage, setModalImage] = useState<string>('');
    const [modalHeatMapImage, setModalHeatMapImage] = useState<string>('');
    const [modalName, setModalName] = useState<string>('');
    const [modalProtocol, setModalProtocol] = useState<string>('');

    const visibleChange = () => {
        props.onClose();

        setModalImage('');
        setModalName('');
        setModalHeatMapImage('');
        setModalProtocol('');
    }    
    useEffect(() => {
        const token = localStorage.getItem('token')
        if(token){
            const get_heat_map = async () => {

            await axios.get('/api/RIQ/GetRIQHeatMap/' + props.imageName, {
                headers: {
                    Accept: '*/*',
                    Authorization: 'Bearer ' + token
                }
            }
            )
            .then((response) => {
                console.log(response)
                setHeatMapFlag(true)
                setModalHeatMapImage(response.data.heatMapImageName)
                setModalImage(response.data.imageName)
                setModalName(props.imageName)
            })
            .catch((error) => {
                console.log(error)
                setHeatMapError(error.response)
                console.log(heatMapError)
            })
        };
        get_heat_map();
    }
    }, [])
    useEffect(() => {
        const token1 = localStorage.getItem('token')
        if(token1){
            const get_protocol = async () => {

            await axios.get('/api/RIQ/GetRIQProtocol/' + props.imageName , {
                headers: {
                    Accept: '*/*',
                    Authorization: 'Bearer ' + token1
                }
            }
            )
            .then((response) => {
                console.log(response)
                setModalProtocol(response.data.protocol)
            })
            .catch((error) => {
                console.log(error)
                setProtocolError(error.response)
                console.log(protocolError)
            })
            };
        get_protocol();
        }
    }, [])

    return (            
                        <Modal isVisible={props.modalClicked} onClose={() => {visibleChange()}}>
                            {heatMapFlag ?
                            <div>
                                                <RIQContainer>
                                                <Wrapper>
                                                <ModalImage src={modalImage} />
                                                <ModalImage src={modalHeatMapImage} />
                                                </Wrapper>
                                                </RIQContainer>

                                                <SmallText> {modalName}</SmallText>
                                                    <ColumnWrapper flexDirection="column"
                                                                   alignItems="center">                                               
                                                        <ProtocolWrapper flexDirection="row"
                                                                 alignItems="center">
                                                                                                           
                                                            <BoldText> Protocol: {modalProtocol ? modalProtocol : "Not available"}</BoldText>
                                                        </ProtocolWrapper>
                                                    </ColumnWrapper>
                                </div>  : <ModalSingleImage src={props.imageURL} /> }     
                        </Modal>
                        
    )
}

export default HeatMap
export const TRANSLATIONS_EN = {
    siteTitle: 'Exam Review',
    analysisOutput: 'Analysis Output',
    diagnosticResult: 'Diagnostic Results',
    username: 'Username',
    password: 'Password',
    login: 'Log in',
    changePasswordMessage: 'You must change your temporary password',
    sessionId: 'Session Id',
    licenceId: 'License Id',
    licenseName: 'License Name',
    somethingWrong: 'Unable to access the exam.'
}
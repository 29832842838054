import styled from "styled-components"
import {PageText} from "../../../../utils/PageText"
import {PageItemWrapper} from "../../../../utils/ItemWrapper"

export const Container = styled.div`
    display: flex;
    padding: 1em;
`
export const RightContainer = styled.section`
    height:100vh;
    width: 82vw;
    display: flex;
    flex-direction: column;
    margin-left: 10em;
    margin-top: 2em;
    margin-bottom: 2em;
    border:2px solid #000000;
`

export const Image = styled.img`
    height: 12em;
    width: 12em;
    background: white;
    border:2px solid #000000;
`
export const ModalImage = styled(Image)`
    height: 20em;
    width: 20em;
`
export const ModalSingleImage = styled(Image)`
    height: 55em;
    width: 55em;
`
export const Text = styled(PageText)`
    color:${(props)=>props.color ? props.color :"#000000" };
    font-size:${(props)=>props.fontSize ? props.fontSize :".9em" };
`
export const SmallText = styled(PageText)`
    margin-right: .3em;
    margin-left: .3em;
    color:${(props)=>props.color ? props.color :"#ffffff" };
    font-size:${(props)=>props.fontSize ? props.fontSize :".9em" };
`
export const BoldText = styled(Text)`
    font-weight: bold;
    margin-right: .2em;
    font-size:${(props)=>props.fontSize ? props.fontSize :"2em" };
`
export const Paragraph = styled.p`
    font-size:.9em;
    display: flex;
    align-items: center;
    padding-bottom: .1em;
`
export const SearchResultDiv = styled.div`
    border: 1px solid #ffffff;
    padding: .6em;
    width: 95%;
    border-radius: 4px;
`
export const RIQContainer = styled.div`
    display: grid;
    background-color: #707070;
    grid-template-columns: repeat(auto-fit, 21em);
    margin-top: 3em;
    @media(max-width:915px){
        grid-template-columns: repeat(auto-fit, 15em);
        align-items: center;
        justify-content: center;
    }
`
export const ImageContainer = styled.div`
    height: 14em;
    display: flex;
    align-items: center;
`
export const ColumnWrapper = styled(PageItemWrapper)`
    display: flex;
    border: 3px solid #000000;
    background-color: #ffffff;
    width: 56em;
    flex-direction: ${(props)=>props.flexDirection ? props.flexDirection  : "column"};
    align-items:  ${(props)=>props.alignItems ? props.alignItems  : "flex-start"};
`

export const Wrapper = styled(PageItemWrapper)`
    display: flex;
    margin-left: 7em;
    flex-direction: ${(props)=>props.flexDirection ? props.flexDirection  : "row"};
    align-items:  ${(props)=>props.alignItems ? props.alignItems  : "flex-start"};
`

export const ProtocolWrapper = styled(Wrapper)`
    margin-left: 32em;
`

export const ImageName = styled(Text)`
    text-align: center;
    margin: 5px auto;
    font-size: 1.3em;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: fit-content;
`
export const BorderWrapper = styled(Wrapper)`
    border:2px solid #000000;
    margin-left: .5em;
    margin-top: .5em;
`
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: null,
  error: null,
  loading: false
}

const reducer = (state = initialState, action: { type: string, user?: any, error?: any}) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true
      }
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        error: null
      }
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        user: null
      }
    case actionTypes.AUTH_RESET:
      return {
        ...state,
        loading: false,
        error: null
      }
    default:
      return state
  }
}

export default reducer;
